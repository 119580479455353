<template>
  <div>
    <Top />
    <v-container style="max-width: 900px; padding-bottom: 69px" class="mt-2">
      <v-list two-line>
        <template v-for="(item, index) in items">
          <v-list-item :key="index" to="/message">
            <v-list-item-avatar>
              <img :src="item.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-container>
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle: `<span class="font-weight-bold">Spike Lee</span> &mdash; I'll be in your neighborhood`,
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">to Operations support</span> &mdash; Wish I could come.',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Bella</span> &mdash; Do you have Paris recommendations',
        },

        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
        },

        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title:
            'Winter Porridge <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">cc: Daniel</span> &mdash; Tell me more...',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle: `<span class="font-weight-bold">Spike Lee</span> &mdash; I'll be in your neighborhood`,
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">to Operations support</span> &mdash; Wish I could come.',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Bella</span> &mdash; Do you have Paris recommendations',
        },

        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
        },

        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title:
            'Winter Porridge <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">cc: Daniel</span> &mdash; Tell me more...',
        },
        {
          avatar: require("@/assets/avatar.jpg"),
          title: "Name Account",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?',
        },
      ],
    };
  },
};
</script>